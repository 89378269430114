import "styles/pages/about.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const About = () => {
  const title = "Um okkum"
  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} />

      <section className="about-item">
        <div className="container-fluid">
          <div className="about-item__header">
            <h3>Um MBM</h3>
          </div>
          <div className="about-item__content">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src={require("assets/images/about-image-1.jpg").default}
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <p>
                  Vaksandi eftirspurningurin eftir mjólk elvdi til, at framfúsir
                  bøndur í 1977 skipaðu seg í eitt lutafelag fyri
                  mjólkaframleiðarar undir navninum Mjólkarvirki Búnaðarmanna
                  við tí tí fyri eyga at tryggja føroyingum føroyska mjólk. Stór
                  menning er farin fram í føroyskari landbúnaðarframleiðslu
                  síðani stovnanina av Mjólkarvirki Búnaðarmanna, og tí varð
                  navnabroytingin til Meginfelag Búnaðarmanna framd í 1996.
                </p>
                <p>
                  Í 2016 varð avgerð tikin um at býta virksemið felagsins í tvær
                  eindir - framvegis undir sama felag, men við hvør sínum heiti;
                  Raska og Búrøkt. Meðan Raska tekur sær av at virka mjólkina og
                  støðugt menna nýggjar spennandi mat- og drekkivørur úr
                  føroysku mjólkini, veitir Búrøkt vørur og tænastur til
                  landbúnaðin. Í høvuðssætinum í Hoyvík er snotiligur
                  amboðshandil við øllum, tær tørvar til landbúnaðin og
                  heimabrúkið.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-item">
        <div className="container-fluid">
          <div className="about-item__header">
            <h3>Dugnalig starvsfólk</h3>
          </div>
          <div className="about-item__content">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src={require("assets/images/about-image-2.jpg").default}
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <p>
                  MBM hevur eina breiða fjøld av starvsfólkum, ið umfatar bæði
                  meiaristar, pakkarar, laborantar, sølufólk, góðskufólk,
                  bilførarar og fyrisitingarlig starvsfólk. Umleið 50
                  starvsfólk, sum í góðum og tøttum samstarvi hvønn ársins dag
                  og hvønn dagsins tíma virka fyri at fáa føroysku mjólkina út í
                  handlarnar - við málinum um høgari góðsku og góðari kundarøkt
                  sum drívmegi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-item">
        <div className="container-fluid">
          <div className="about-item__header">
            <h3>Vælsmakkandi vørur</h3>
          </div>
          <div className="about-item__content">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src={require("assets/images/about-image-3.jpg").default}
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <p>
                  Á mjólkavirkinum í Hoyvík virka vit føroysku mjólkina til
                  fleiri enn 60 vælsmakkandi mat- og drekkivørur. Eitt
                  fjøllbroytt vøruúrval, ið fevnir um alt frá osti, smør og róma
                  til jogurt, summarskál og kakao - og allatíðina leggjast
                  nýggjar spennandi vørur og ymisk sløg av smakkum afturat. Vit
                  vilja evna sum mest og best burturúr frísku mjólkini, sum
                  bøndurnir hvønn morgun lata okkum. Á mjólkarvirkinum hjá MBM
                  finnur tú djúpar tangar við búnandi osti, lítil íløt við
                  serligum heilsugóðum bakterium, stórar kvørnir og hátøkniligar
                  maskinur - aldargamlir framleiðsluhættir lið um lið við
                  nýggjastu tøkni, sum tryggja, at vit kunnu veita tær bestu
                  mat- og drekkivørurnar á borðið. Málið er makaleys góðska og
                  framúrskarandi smakkir!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default About
